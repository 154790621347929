:root {
  // Primary
  --desaturated-red: hsl(0, 36%, 70%);
  --soft-red: hsl(0, 93%, 68%);

  // Neutral
  --dark-Grayish-red: hsl(0, 6%, 24%);

  // Gradients
  --gradient-pinkish: linear-gradient(
    135deg,
    hsl(0, 80%, 86%),
    hsl(0, 74%, 74%)
  );
  --gradient-pinkish-alt: linear-gradient(
    135deg,
    hsl(0, 74%, 74%),
    hsl(0, 80%, 86%)
  );
  --gradient-whiteish: linear-gradient(
    135deg,
    hsl(0, 0%, 100%),
    hsl(0, 100%, 98%)
  );

  --success: #007e33;
}

$bp-small: 600px;
$bp-medium: 700px;
$bp-large: 1000px;

::selection {
  background-color: var(--desaturated-red);
  color: var(--dark-Grayish-red);
}

html {
  @media screen and (min-width: $bp-small) {
    font-size: 100%;
  }

  @media screen and (min-width: $bp-large) {
    font-size: 150%;
  }
}

body {
  font-family: "Josefin Sans", sans-serif;
  background: var(--gradient-whiteish);
  color: var(--desaturated-red);
  overflow-x: hidden;
}

.main-contents {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "logo"
    "hero"
    "contents";
  max-width: $bp-small;
  margin: auto;

  @media screen and (min-width: $bp-small) {
    max-width: 1440px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "logo hero"
      "contents hero";

    min-height: calc(100vh - 101.6px); //Incase dvh is not supported
    min-height: calc(100dvh - 101.6px);
  }
}
