@import "https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600&display=swap";
*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
  font-size: xx-large;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a:not([class]) {
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }

  *, :before, :after {
    -o-transition-duration: .01ms !important;
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

:root {
  --desaturated-red: #ce9797;
  --soft-red: #f96262;
  --dark-Grayish-red: #413a3a;
  --gradient-pinkish: linear-gradient(135deg, #f8bfbf, #ee8c8c);
  --gradient-pinkish-alt: linear-gradient(135deg, #ee8c8c, #f8bfbf);
  --gradient-whiteish: linear-gradient(135deg, #fff, #fff5f5);
  --success: #007e33;
}

::selection {
  background-color: var(--desaturated-red);
  color: var(--dark-Grayish-red);
}

@media screen and (width >= 600px) {
  html {
    font-size: 100%;
  }
}

@media screen and (width >= 1000px) {
  html {
    font-size: 150%;
  }
}

body {
  background: var(--gradient-whiteish);
  color: var(--desaturated-red);
  font-family: Josefin Sans, sans-serif;
  overflow-x: hidden;
}

.main-contents {
  max-width: 600px;
  grid-template-columns: 1fr;
  grid-template-areas: "logo"
                       "hero"
                       "contents";
  margin: auto;
  display: grid;
}

@media screen and (width >= 600px) {
  .main-contents {
    max-width: 1440px;
    min-height: calc(100vh - 101.6px);
    min-height: calc(100dvh - 101.6px);
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "logo hero"
                         "contents hero";
  }
}

.top-section {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.logo {
  max-width: 60%;
  filter: brightness(var(--brightness));
  grid-area: logo;
  padding: 2rem;
}

@media screen and (width >= 600px) {
  .logo {
    padding: 2rem 1rem;
  }
}

@media screen and (width >= 700px) {
  .logo {
    padding: 2rem 3rem;
  }
}

.hero-image {
  grid-area: hero;
  margin: auto;
}

@media screen and (width >= 600px) {
  .hero-image {
    height: 100%;
    margin-right: 0;
  }

  .hero-image img {
    content: url("hero-desktop.2f33b271.jpg");
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.contents {
  text-align: center;
  grid-area: contents;
  padding: 0 2rem;
  font-size: .9rem;
}

@media screen and (width >= 600px) {
  .contents {
    text-align: left;
    padding: 1rem;
  }
}

@media screen and (width >= 700px) {
  .contents {
    padding: 1rem 3rem;
  }
}

.contents > :not(:last-child) {
  margin-bottom: 1rem;
}

.contents__header {
  text-transform: uppercase;
  color: var(--dark-Grayish-red);
  letter-spacing: 10px;
  margin-top: 3rem;
  font-size: clamp(2rem, 10vw, 3.5rem);
  font-weight: 400;
  line-height: 1;
}

@media screen and (width >= 700px) {
  .contents__header {
    margin-top: 0;
  }
}

.contents__header span {
  color: var(--desaturated-red);
  font-weight: 300;
}

.contents .form {
  height: 3rem;
  position: relative;
}

.contents .form .input-email {
  height: 100%;
  width: 100%;
  border: 1px solid var(--desaturated-red);
  color: var(--desaturated-red);
  background-color: var(--white);
  border-radius: 9999px;
  padding: 0 5.8rem 0 1.5rem;
}

.contents .form .input-email:hover {
  border-width: 2px !important;
}

.contents .form .input-email:focus {
  outline-offset: 1px;
  outline-color: var(--desaturated-red);
  border-width: 2px;
  outline-width: 3px;
  outline-style: dotted;
}

.contents .form .btn-submit {
  height: 100%;
  width: 4rem;
  background: var(--gradient-pinkish);
  box-shadow: 0 5px 20px var(--desaturated-red);
  cursor: pointer;
  border: none;
  border-radius: 99999px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.contents .form .btn-submit img {
  height: 40%;
}

.contents .form .btn-submit:hover {
  background: var(--gradient-pinkish-alt);
}

.contents .form .btn-submit:active {
  background: var(--gradient-pinkish);
}

.contents .form .error-icon {
  -webkit-user-select: none;
  user-select: none;
  max-width: 1.5rem;
  display: none;
  position: absolute;
  top: 50%;
  right: 4.2rem;
  transform: translateY(-50%);
}

.contents .form .error-output {
  position: absolute;
  bottom: -2rem;
  left: 1rem;
}

:root.dark-mode {
  --desaturated-red: #f8b9b9;
  --dark-Grayish-red: #b5a6a6;
  --gradient-whiteish: linear-gradient(135deg, #13121c, #151424);
  --success: #00d856;
  --white: #13121d;
  --brightness: 200%;
}

.dark-mode-checkbox {
  display: none;
}

.dark-mode-lebel {
  visibility: hidden;
  --sh-height: 1.7rem;
  --border: calc(var(--sh-height) / 15);
  width: calc(var(--sh-height) * 2);
  height: var(--sh-height);
  border: var(--border) solid #38447a;
  cursor: pointer;
  background-color: #090831;
  border-radius: 99999px;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
  padding: 0 1px;
  display: flex;
  position: relative;
  box-shadow: 0 0 10px #181b304d;
}

.dark-mode-lebel > * {
  -webkit-user-select: none;
  user-select: none;
}

@media screen and (width >= 600px) {
  .dark-mode-lebel {
    --sh-height: 2rem;
  }
}

.dark-mode-lebel .dark-mode-middle-circle {
  width: var(--sh-height);
  height: calc(var(--sh-height)  - var(--border)  - var(--border));
  border: var(--border) solid #6770ee;
  background-color: #d4dbfd;
  border-radius: 99999px;
  transition: left .2s ease-in-out;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
}

.dark-mode-lebel > img {
  height: calc(var(--sh-height)  - .5rem);
  width: calc(var(--sh-height)  - .5rem);
}

.dark-mode-checkbox:checked ~ .dark-mode-lebel .dark-mode-middle-circle {
  left: calc(100% - var(--sh-height));
}

.attribution {
  --fs-attb: 13px;
  --clr-attb-primary: var(--soft-red);
  --clr-attb-secondary: var(--desaturated-red);
  color: var(--clr-Black);
  text-align: center;
  line-height: 2;
  font-size: var(--fs-attb);
  border-left: 2px solid var(--clr-attb-primary);
  border-right: 2px solid var(--clr-attb-primary);
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 10px;
  margin: 3rem auto 16px;
  padding: .3rem .5rem;
}

.attribution .attb-link {
  color: var(--clr-attb-primary);
  -o-transition: all .2s ease;
  -webkit-transition: all .2s ease-in-out;
  border-bottom: 2px dotted var(--clr-attb-primary);
  font-size: calc(var(--fs-attb)  + 4px);
  padding: 0 5px;
  text-decoration: none;
  transition: all .2s;
}

.attribution .attb-link:hover, .attribution .attb-link:focus {
  color: var(--clr-attb-secondary);
}

.attribution .attb-fem {
  font-size: calc(var(--fs-attb)  - 2px);
}

/*# sourceMappingURL=index.bed0bd4b.css.map */
