.top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  grid-area: logo;
  padding: 2rem;
  max-width: 60%;
  filter: brightness(var(--brightness));

  @media screen and (min-width: $bp-small) {
    padding: 2rem 1rem;
  }

  @media screen and (min-width: $bp-medium) {
    padding: 2rem 3rem;
  }
}

.hero-image {
  grid-area: hero;
  margin: auto;

  @media screen and (min-width: $bp-small) {
    height: 100%;
    margin-right: 0;

    img {
      content: url(../images/hero-desktop.jpg);
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.contents {
  grid-area: contents;
  font-size: 0.9rem;
  text-align: center;
  padding: 0 2rem;

  @media screen and (min-width: $bp-small) {
    text-align: left;
    padding: 1rem 1rem;
  }

  @media screen and (min-width: $bp-medium) {
    padding: 1rem 3rem;
  }

  > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__header {
    text-transform: uppercase;
    font-size: 2.5rem; // incase clamp is not supported
    font-size: clamp(2rem, 10vw, 3.5rem);
    font-weight: 400;
    line-height: 1;
    color: var(--dark-Grayish-red);
    letter-spacing: 10px;
    margin-top: 3rem;

    @media screen and (min-width: $bp-medium) {
      margin-top: 0;
    }

    span {
      font-weight: 300;
      color: var(--desaturated-red);
    }
  }

  .form {
    height: 3rem;
    position: relative;

    .input-email {
      height: 100%;
      width: 100%;
      border: 1px solid var(--desaturated-red);
      color: var(--desaturated-red);
      border-radius: 9999px;
      padding: 0 5.8rem 0 1.5rem;
      background-color: var(--white);

      &:hover {
        border-width: 2px !important;
      }

      &:focus {
        outline-offset: 1px;
        outline-color: var(--desaturated-red);
        outline-style: dotted;
        outline-width: 3px;
        border-width: 2px;
      }
    }

    .btn-submit {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 4rem;
      background: var(--gradient-pinkish);
      border: none;
      border-radius: 99999px;
      box-shadow: 0 5px 20px var(--desaturated-red);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 40%;
      }

      &:hover {
        background: var(--gradient-pinkish-alt);
      }

      &:active {
        background: var(--gradient-pinkish);
      }
    }

    .error-icon {
      position: absolute;
      right: 4.2rem;
      top: 50%;
      transform: translateY(-50%);
      display: none;
      user-select: none;
      max-width: 1.5rem;
    }

    .error-output {
      position: absolute;
      left: 1rem;
      bottom: -2rem;
    }
  }
}
