.attribution {
  --fs-attb: 13px;
  --clr-attb-primary: var(--soft-red);
  --clr-attb-secondary: var(--desaturated-red);
  color: var(--clr-Black);
  text-align: center;
  line-height: 2;
  font-size: var(--fs-attb);
  padding: 0.3rem 0.5rem;
  border-left: 2px solid var(--clr-attb-primary);
  border-right: 2px solid var(--clr-attb-primary);
  border-radius: 10px;
  margin: 16px auto;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  ////
  margin-top: 3rem;

  .attb-link {
    color: var(--clr-attb-primary);
    padding: 0 5px;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-bottom: 2px dotted var(--clr-attb-primary);
    font-size: calc(var(--fs-attb) + 4px);
    text-decoration: none;

    &:hover,
    &:focus {
      color: var(--clr-attb-secondary);
    }
  }

  .attb-fem {
    font-size: calc(var(--fs-attb) - 2px);
  }
}
